var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.tableData.length > 0
    ? _c(
        "div",
        { staticClass: "multilevel" },
        [
          _c(
            "el-table",
            {
              staticStyle: { width: "100%" },
              attrs: { data: _vm.tableData, height: _vm.tableHeight + 26 }
            },
            [
              _c("el-table-column", {
                attrs: { type: "index", width: "70", label: "序号" }
              }),
              _c("el-table-column", {
                attrs: { prop: "companyName", label: "所属企业", width: "180" }
              }),
              _c("el-table-column", {
                attrs: { prop: "cph", label: "车牌号", width: "100" }
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "licensePlateColor",
                  label: "车牌颜色",
                  width: "100"
                },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "default",
                      fn: function(scope) {
                        return [
                          _vm._v(
                            _vm._s(
                              _vm.colorObj[Number(scope.row.licensePlateColor)]
                            )
                          )
                        ]
                      }
                    }
                  ],
                  null,
                  false,
                  227092649
                )
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "type",
                  label: "车辆类型",
                  "show-overflow-tooltip": true,
                  width: "150"
                },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "default",
                      fn: function(scope) {
                        return [
                          _vm._v(_vm._s(_vm.typeObj[Number(scope.row.type)]))
                        ]
                      }
                    }
                  ],
                  null,
                  false,
                  4184889086
                )
              }),
              _vm._l(_vm.tableData[0].list, function(item, index) {
                return _c(
                  "el-table-column",
                  { key: index, attrs: { label: item.dataDate } },
                  [
                    _c("el-table-column", {
                      attrs: { label: "总里程", width: "110" },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "default",
                            fn: function(scope) {
                              return [
                                _vm._v(
                                  _vm._s(scope.row.list[index].positionRunMile)
                                )
                              ]
                            }
                          }
                        ],
                        null,
                        true
                      )
                    }),
                    _c("el-table-column", {
                      attrs: { label: "完整轨迹里程", width: "110" },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "default",
                            fn: function(scope) {
                              return [
                                _vm._v(_vm._s(scope.row.list[index].runMile))
                              ]
                            }
                          }
                        ],
                        null,
                        true
                      )
                    }),
                    _c("el-table-column", {
                      attrs: { label: "缺失轨迹里程", width: "110" },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "default",
                            fn: function(scope) {
                              return [
                                _vm._v(_vm._s(scope.row.list[index].dvalueMile))
                              ]
                            }
                          }
                        ],
                        null,
                        true
                      )
                    }),
                    _c("el-table-column", {
                      attrs: { label: "轨迹完整率", width: "110" },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "default",
                            fn: function(scope) {
                              return [
                                _vm._v(
                                  _vm._s(
                                    scope.row.list[index].completeRate + "%"
                                  )
                                )
                              ]
                            }
                          }
                        ],
                        null,
                        true
                      )
                    }),
                    _c("el-table-column", {
                      attrs: { label: "在线率", width: "110" },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "default",
                            fn: function(scope) {
                              return [
                                _vm._v(
                                  _vm._s(scope.row.list[index].onlineRate + "%")
                                )
                              ]
                            }
                          }
                        ],
                        null,
                        true
                      )
                    })
                  ],
                  1
                )
              })
            ],
            2
          ),
          _c(
            "div",
            { staticClass: "pagination" },
            [
              _c("el-pagination", {
                attrs: {
                  background: "",
                  layout: "total, sizes, prev, pager, next, jumper",
                  total: _vm.total,
                  "page-size": _vm.pageObj.pageSize,
                  "current-page": _vm.pageObj.currentPage
                },
                on: {
                  "size-change": _vm.handleSizeChange,
                  "current-change": _vm.handleCurrentChange
                }
              })
            ],
            1
          )
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }