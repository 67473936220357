<template>
  <div class="mileage-statistics" ref="mileageStatistics">
    <el-tabs type="border-card" v-model="form.menuType" @tab-click="clickTab">
      <el-tab-pane label="统计" name="0">
        <div class="form-area" ref="form">
          <analysis-form @getFormVal="onSearch" :formatDay="true" :showCar="false">
            <el-button
              type="primary"
              size="small"
              :disabled="comArr.length === 0"
              :loading="downLoadStatus"
              @click="exportData(1)"
              >导出</el-button
            >
          </analysis-form>
        </div>
        <!-- 企业统计 -->
        <div class="form-table" v-if="comArr.length > 0">
          <el-table :data="comArr" :height="tableHeight" stripe class="left">
            <el-table-column prop="dataDate" label="日期"></el-table-column>
            <el-table-column prop="positionRunMile" label="总里程"></el-table-column>
            <el-table-column prop="runMile" label="完整轨迹里程"></el-table-column>
            <el-table-column prop="dvalueMile" label="缺失轨迹里程"></el-table-column>
            <el-table-column prop="completeRate" label="轨迹完整率">
              <template slot-scope="scope">{{ scope.row.completeRate + "%" }}</template>
            </el-table-column>
            <el-table-column prop="onlineRate" label="在线率">
              <template slot-scope="scope">{{ scope.row.onlineRate + "%" }}</template>
            </el-table-column>
          </el-table>
          <charts :option="itemOption" :id="item" class="right"></charts>
        </div>
      </el-tab-pane>
      <el-tab-pane label="详情" name="1">
        <div class="form-area">
          <analysis-form @getFormVal="onSubmit" :formatDay="true">
            <el-button
              type="primary"
              size="small"
              :disabled="carArr.length === 0"
              @click="exportData(2)"
              >导出</el-button
            >
          </analysis-form>
        </div>
        <!-- 车辆明细 -->
        <car-table
          ref="pagination2"
          :height="tableHeight"
          :total="total"
          @sendInfo="sendInfo"
          :tableData="carArr"
        />
      </el-tab-pane>
    </el-tabs>
  </div>
</template>

<script>
import analysisForm from "@/components/analysisForm/analysisFormNew.vue";
// import analysisForm from "@/components/analysisForm/analysisFormDemo.vue";
import charts from "@/components/charts/charts.vue";
import carTable from "./components/carTable.vue";
import { queryMileForm } from "@/api/lib/gps-api.js";

import { addExcelExport } from "@/api/lib/refreshAlarm.js";
import { getCurent, formatDate } from "@/common/utils/index";

export default {
  name: "mileageStatistics",
  components: {
    analysisForm,
    charts,
    carTable,
  },
  data() {
    return {
      tableHeight: 0,
      tableData: [],
      form: {
        currentPage: 1,
        pageSize: 10,
        menuType: "0",
        vehicleNos: [],
        companyId: null,
        beginTime: null,
        endTime: null,
      },
      carArr: [], //   车辆明细
      comArr: [],
      total: 0,
      modelObj: null,
      item: "item",
      itemOption: {
        grid: {
          top: 40,
          left: 80,
          right: 80,
          bottom: 40,
        },
        tooltip: {
          show: true,
          trigger: "axis",
          textStyle: {
            fontSize: 12,
          },
        },
        xAxis: {
          type: "category",
          boundaryGap: false,
          axisLine: {
            show: true,
            lineStyle: {
              color: "#D9D9D9",
            },
          },
          axisTick: {
            lineStyle: {
              color: "#D9D9D9",
            },
          },
          axisLabel: {
            color: "#666666",
            fontSize: 12,
          },
          splitLine: {
            show: false,
          },
          data: [],
        },
        yAxis: [
          {
            type: "value",
            axisLine: {
              show: false,
            },
            axisTick: {
              show: false,
            },
            axisLabel: {
              color: "#666666",
              fontSize: 12,
            },
            splitLine: {
              show: true,
              lineStyle: {
                type: "dashed",
                color: "#E9E9E9",
              },
            },
          },
          {
            type: "value",
            position: "right",
            axisLine: {
              show: false,
            },
            axisTick: {
              show: false,
            },
            axisLabel: {
              color: "#666666",
              fontSize: 12,
            },
            splitLine: {
              show: true,
              lineStyle: {
                type: "dashed",
                color: "#E9E9E9",
              },
            },
          },
        ],
        series: [
          {
            name: "总里程",
            data: [],
            type: "line",
            smooth: true,
            yAxisIndex: 0,
          },
          {
            name: "完整轨迹里程",
            data: [],
            type: "line",
            smooth: true,
            yAxisIndex: 0,
          },
          {
            name: "缺失轨迹里程",
            data: [],
            type: "line",
            smooth: true,
            yAxisIndex: 0,
          },
          {
            name: "轨迹完整率",
            data: [],
            type: "line",
            smooth: true,
            yAxisIndex: 1,
          },
          {
            name: "在线率",
            data: [],
            type: "line",
            smooth: true,
            yAxisIndex: 1,
          },
        ],
      },
      exportForm: {},
      downLoadStatus: false,
    };
  },
  methods: {
    // 计算表格高度
    computeHeight() {
      const wholeHeight = this.$refs.mileageStatistics.clientHeight;
      const formHeight = this.$refs.form.clientHeight || 64;
      this.tableHeight = wholeHeight - formHeight - 40 - 30 - 40 - 24;
    },
    //导出
    exportData(key) {
      this.downLoadStatus = true;
      delete this.exportForm.currentPage;
      delete this.exportForm.pageSize;
      let data = {
        baseUrl: "report",
        userId: sessionStorage.getItem("userId")||localStorage.getItem('userId'),
        createTime: formatDate(new Date()),
        fileName:
          key === 1 ? `里程轨迹统计-${getCurent()}` : `里程轨迹详情-${getCurent()}`,
        filePath: null,
        fileStatus: 1,
        generateTime: null,
        queryParam: JSON.stringify(this.exportForm),
        queryPath:
          key === 1
            ? `/vehicleStatistics/mileageStatisticsExport`
            : `/vehicleStatistics/mileageDetailExport`,
      };
      addExcelExport(data)
        .then((res) => {
          this.$message.success(res.msg);
          this.downLoadStatus = false;
          this.$store.dispatch("setRefreshStatus", true);
        })
        .catch(() => {
          this.downLoadStatus = false;
        });
    },

    // 点击统计查询按钮
    onSearch(value) {
      this.form.vehicleNos = value.vehicleNos;
      this.form.companyId = value.companyId;
      this.form.beginTime = value.beginTime;
      this.form.endTime = value.endTime;
      this.getDataList();
    },

    // 点击统详情查询按钮
    onSubmit(value) {
      this.form.vehicleNos = value.vehicleNos;
      this.form.companyId = value.companyId;
      this.form.beginTime = value.beginTime;
      this.form.endTime = value.endTime;
      this.getPageInfo();
    },

    /**切换统计|详情 */
    clickTab() {
      this.comArr = [];
      this.carArr = [];
      this.total = 0;
      this.form.currentPage = 1;
      this.form.pageSize = 10;
    },

    // 获取统计列表
    getDataList() {
      let data = { ...this.form };
      queryMileForm(data).then((res) => {
        if (res.code === 1000) {
          this.comArr = res.data.list;
          this.itemOption.xAxis.data = [];
          this.itemOption.series[0].data = [];
          this.itemOption.series[1].data = [];
          this.itemOption.series[2].data = [];
          this.itemOption.series[3].data = [];
          this.itemOption.series[4].data = [];
          this.comArr.forEach((item) => {
            if (item.dataDate !== "合计") {
              this.itemOption.xAxis.data.push(item.dataDate);
              this.itemOption.series[0].data.push(item.positionRunMile);
              this.itemOption.series[1].data.push(item.runMile);
              this.itemOption.series[2].data.push(item.dvalueMile);
              this.itemOption.series[3].data.push(item.completeRate);
              this.itemOption.series[4].data.push(item.onlineRate);
            }
          });
        } else {
          this.comArr = [];
          this.$message.error(res.msg);
        }
        this.exportForm = { ...data };
      });
    },

    // 翻页
    sendInfo(info) {
      this.form.currentPage = info.currentPage;
      this.form.pageSize = info.pageSize;
      this.getPageInfo();
    },

    // 获取详情列表
    getPageInfo() {
      let data = { ...this.form };
      queryMileForm(data).then((res) => {
        if (res.code === 1000) {
          this.carArr = res.data.list;
          this.total = res.data.total;
        } else {
          this.carArr = [];
          this.total = 0;
          this.$message.error(res.msg);
        }
        this.exportForm = { ...data };
      });
    },
  },
  created() {},
  mounted() {
    this.$nextTick(() => {
      this.computeHeight();
      window.addEventListener("resize", this.computeHeight, false);
    });
  },
  destroyed() {
    window.removeEventListener("resize", this.computeHeight);
  },
};
</script>

<style lang="scss" scoped>
.mileage-statistics {
  height: 100%;
  width: 100%;
  ::v-deep {
    .el-tabs {
      height: 100%;
      box-sizing: border-box;
    }
  }
}
.theme-project-gps {
  .mileage-statistics {
    background: #ffffff;
    box-shadow: 0 0 4px 0 rgba($color: #343434, $alpha: 0.1);
    .form-table {
      box-sizing: border-box;
      display: flex;
      flex-wrap: nowrap;
      .left {
        flex: 3;
      }
      .right {
        flex: 2;
        height: 400px;
        width: 100%;
      }
    }
  }
}
</style>
